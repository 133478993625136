<template>
  <div class="plan-step-page-container">
    <Button type="primary" icon="ios-arrow-back" class="back-button" @click="$emit('back')"></Button>
    <div class="plan-step-header-container">
      <div class="plan-step-title-container">
        <span style="font-size: 32px; font-weight: bold">使用Excel导入付款列表</span>
        <span style="font-size: 13px; font-weight: bold">😊
          温馨提示，建议您使用我们提供的模版进行导入，以免出现信息的不兼容</span>
        <a :href="ossHost + 'upload_template_pay.xlsx'"
          style="font-size: 12px; color: #3483bf; margin-top: 10px">下载模板</a>
      </div>
      <img :src="icons.xls" style="width: 100px; height: 100px" />
    </div>

    <span>项目名称：{{ name }}</span>
    <Upload :show-upload-list="false" :before-upload="handleBeforeUpload" action>
      <Button type="primary" style="width: 130px">点击上传</Button>
    </Upload>

    <xls-list :dataSource="excel" @deleteItem="deleteItem" />

    <Button size="large" type="primary" style="width: 150px; margin: 50px 0px" @click="next">生成付款列表</Button>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import XlsList from "../../../components/List/XlsList";
import * as icon from "../../../common/icon";
import * as arrayHelper from "../../../utils/arrayHelper";
import * as oss from "../../../utils/ossHelper";
import { ossHost } from "../../../common/env";
import moment from "moment";
export default {
  props: {
    projectId: Number,
    excel: Array,
  },
  components: {
    "xls-list": XlsList,
  },
  data() {
    return {
      name: "",
      ossHost: ossHost,
      icons: icon,
    };
  },
  mounted() {
    this.projectGetBasicAction({
      employer_id: localStorage.getItem("employer_id"),
      project_id: this.projectId,
    }).then((res) => {
      this.name = res.name;
    });
  },
  methods: {
    ...mapActions({
      departmentAddAction: "departmentAdd",
      projectGetBasicAction: "projectGetBasic",
      milestoneGetExcelDataAction: "milestoneGetExcelData",
    }),
    handleBeforeUpload(file) {
      this.$Spin.show();
      oss.upload(file, "milestone").then((result) => {
        this.excel.push({
          file_name: result.name,
          file_url: result.fullPath,
          size: file.size,
          create_user_id: localStorage.getItem("user_id"),
          create_user_name: localStorage.getItem("user_name"),
          create_user_img_url: localStorage.getItem("img_url"),
          create_time: moment().format("YYYY-MM-DD HH:mm"),
        });
        this.$Spin.hide();
      });
      return false;
    },
    deleteItem(acc) {
      arrayHelper.remove(this.excel, (item) => {
        return item.file_url == acc.file_url;
      });
    },
    next() {
      if (this.excel.length == 0) {
        this.$Message.error("请上传excel");
        return;
      }
      this.milestoneGetExcelDataAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
        excel: this.excel,
      })
        .then((res) => {
          this.$emit("next");
        })
        .catch((error) => {
          if (error.res_code == -45) {
            // 当res_code为-45时,询问是否创建部门
            this.$Modal.confirm({
              cancelText: "取消",
              okText: "确定",
              title: "excel错误",
              content: `不存在部门：${error.msg.join("，")}，是否前往创建？`,
              onOk: () => {
                this.departmentAddAction({
                  employer_id: localStorage.getItem("employer_id"),
                  project_id: this.projectId,
                  department_arr: error.msg,
                })
                  .then((res) => {
                    this.$Message.success("创建成功");
                    this.next();
                  })
                  .catch((error) => {
                    this.$Message.error(error);
                  });
              },
              onCancel: () => {
                this.$emit("exit");
              },
            });
          } else {
            this.$Notice.error({
              title: "excel错误",
              desc: error.msg,
              duration: 0,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.plan-step-page-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.plan-step-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.plan-step-title-container {
  display: flex;
  flex-direction: column;
}
.plan-step-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  padding-bottom: 10px;
}
.plan-step-table-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  min-height: 50px;
}
.accessories-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>