<template>
  <div class="milestone-item-root-container">
    <Icon
      type="md-checkmark-circle"
      class="milestone-item-select"
      :size="20"
      color="#19be6b"
      v-if="dataSource.isSelected"
    />
    <div class="milestone-item-title-row-container">
      <my-avatar :name="dataSource.create_user_name" :img_url="dataSource.create_user_img_url" :size="24"></my-avatar>
      <div class="milestone-item-title-container">
        <span style="font-size:15px;font-weight:500;">{{ dataSource.create_user_name }}</span>
        <span style="font-size:15px;font-weight:500;margin-left: 10px;">发送给你</span>
        <span style="font-size:15px;font-weight:500;color:#104266;margin-left: 10px;">付款请求单</span>
      </div>
    </div>
    <div class="milestone-item-createtime-container">{{ dataSource.create_time }}</div>
    <div class="milestone-item-bill-container">
      <img :src="icons.milestoneType2" style="width:32px;height:37px;" />
      <div class="milestone-item-bill-item-container" style="flex:1;margin-left: 15px;">
        <div style="font-size:13px;font-weight: bold;color:#050505;">{{ dataSource.name }}</div>
        <div
          style="font-size:12px;font-weight: 500;color:#5E5E5E;margin-top:5px;"
        >@{{ dataSource.project_name }}</div>
      </div>
      <div class="milestone-item-bill-item-container" style="align-items:flex-end;">
        <fee :amount="dataSource.total_amount" :primarySize="13" :secondarySize="12" />
        <div
          style="margin-top:5px;font-size:12px;font-weight:500;color:#747474;"
        >包含0个任务,共计{{ dataSource.count }}笔</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
};
</script>

<style scoped>
.milestone-item-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 140px;
  padding: 15px;
  border-radius: 8px;
  margin: 0px 10px;
  cursor: pointer;
}
.milestone-item-select {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
}
.milestone-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.milestone-item-check {
  position: absolute;
  top: 15px;
  right: 15px;
}
.milestone-item-title-row-container {
  display: flex;
  flex-direction: row;
}
.milestone-item-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}
.milestone-item-bill-container {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 0 40px;
  background-color: #f6f7f8;
}
.milestone-item-bill-item-container {
  display: flex;
  flex-direction: column;
}
.milestone-item-createtime-container {
  font-size: 12px;
  font-weight: 500;
  margin-left: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>