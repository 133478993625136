<template>
  <Modal v-model="isShowModal" footer-hide :closable="false" :mask-closable="false">
    <div v-if="isShowModal" class="plan-modal-root-container">
      <div class="plan-top-container">
        <my-avatar :size="80" :name="dataSource.name" />
        <span style="font-size: 23px; font-weight: bold; margin-top: 20px">{{
          dataSource.name
        }}</span>

        <fee style="margin-top: 50px" :amount="totalAmount" :primarySize="23" :secondarySize="20" />
        <span style="font-size: 12px; margin-top: 10px">合计{{ payList.length }}笔</span>
      </div>
      <avatar-list :member-list="payList" :max-count="7" style="margin-top: 20px" />
      <Button style="margin-top: 40px" type="primary" @click="$emit('confirm')">确认创建</Button>
      <Button style="margin-top: 10px" type="text" @click="isShowModal = false">返回</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AvatarList from "../../../components/List/AvatarList";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
    "avatar-list": AvatarList,
  },
  data() {
    return {
      isShowModal: false,
      payList: [],
    };
  },
  computed: {
    totalAmount() {
      let total = 0;
      for (let item of this.payList) {
        total += item.amount;
      }
      return total;
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      milestoneGetPayListAction: "milestoneGetPayList",
      milestoneGetExcelDataAction: "milestoneGetExcelData",
    }),
    open() {
      if (this.dataSource.isByExcel == true) {
        this.milestoneGetExcelDataAction({
          employer_id: localStorage.getItem("employer_id"),
          project_id: this.dataSource.project_id,
          excel: this.dataSource.excel,
        })
          .then((res) => {
            this.payList = res.pay_list;
          })
          .catch((error) => {
            this.$Message.error(error.msg);
          });
      } else {
        this.milestoneGetPayListAction({
          employer_id: localStorage.getItem("employer_id"),
          milestone_id_arr: this.dataSource.milestone_id_arr,
        })
          .then((res) => {
            this.payList = res;
          })
          .catch((error) => {
            this.$Message.error(error);
          });
      }
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped>
.plan-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
.plan-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f7f8;
  width: 400px;
  padding: 20px;
}
</style>