<template>
  <div
    :class="[
      'plan-item-root-container',
      dataSource.isSelected ? 'plan-item-selected-border' : '',
    ]"
    @click="showModal"
  >
    <div class="plan-item-head-container" :style="bgColor">
      <span style="font-size: 15px; font-weight: bold">{{
        dataSource.name
      }}</span>
      <div class="plan-item-head-right-bottom-container">
        <fee
          :amount="dataSource.total_amount"
          :primarySize="15"
          :secondarySize="12"
          style="color: #ffffff !important"
        />
        <span style="font-size: 12px">最近更新于：{{ updateTime }}</span>
      </div>
    </div>
    <div class="plan-item-main-container">
      <div class="plan-item-title-container">
        <my-avatar
          :name="dataSource.company_name"
          :img_url="dataSource.img_url"
          :size="34"
        />
        <span style="margin-left: 15px">{{ dataSource.company_name }}</span>
      </div>
      <avatar-list
        :member-list="dataSource.client_user"
        :max-count="7"
      ></avatar-list>
      <span
        v-if="dataSource.type == 1"
        style="font-size: 12px; margin-top: 30px"
        >合计0个任务，共计{{ dataSource.client_user.length }}人</span
      >
      <span v-else style="font-size: 12px; margin-top: 30px"
        >共计{{ dataSource.client_user.length }}人</span
      >
    </div>

    <div class="plan-item-footer-container">
      <Divider />
      <div v-if="dataSource.type == 1" class="plan-item-items-container">
        <span style="font-size: 12px; font-weight: 500">引用的Excel表格</span>
        <file-item
          v-for="item in dataSource.excel_arr"
          :key="item.id"
          :data-source="item"
        ></file-item>
      </div>
      <div v-else class="plan-item-items-container">
        <span style="font-size: 12px; font-weight: 500">引用的里程碑事件</span>
        <milestone-event-item
          v-for="item in dataSource.milestone_arr"
          :key="item.id"
          :data-source="item"
        ></milestone-event-item>
      </div>
    </div>

    <plan-pay-modal ref="planPayModal" />
  </div>
</template>

<script>
import moment from "moment";
import Fee from "../../../components/Fee";
import FileItem from "./FileItem";
import PlanPayModal from "../Modal/PlanPayModal";
import MyAvatar from "../../../components/MyAvatar";
import MilestoneEventItem from "./MilestoneEventItem";
import AvatarList from "../../../components/List/AvatarList";
export default {
  components: {
    fee: Fee,
    "file-item": FileItem,
    "my-avatar": MyAvatar,
    "avatar-list": AvatarList,
    "plan-pay-modal": PlanPayModal,
    "milestone-event-item": MilestoneEventItem,
  },
  props: {
    dataSource: Object,
    canShowModal: {
      type: Boolean,
      default: false,
    },
    canShowButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: 1,
      bgColor: {},
    };
  },
  mounted() {
    if (this.dataSource.isValid) {
      if (this.dataSource.type == 2) {
        // 里程碑
        this.bgColor = { backgroundColor: "#527BA8" };
      } else {
        // excel
        this.bgColor = { backgroundColor: "#04A073" };
      }
    } else {
      // 作废的计划
      this.bgColor = { backgroundColor: "#505050" };
    }
  },
  methods: {
    showModal() {
      if (!this.canShowModal) {
        return;
      }

      this.$refs.planPayModal.open(
        {
          type: this.dataSource.type,
          pay_plan_id: this.dataSource.pay_plan_id,
        },
        this.canShowButton,
        this.dataSource.isShowBack
      );
    },
  },
  computed: {
    updateTime: function () {
      return moment(this.dataSource.update_time).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style scoped>
.plan-item-root-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-width: 330px;
  max-width: 330px;
  margin-right: 20px;
  border-radius: 11px;
  overflow: hidden;
  margin-top: 15px;
  margin-right: 15px;
  box-shadow: 0px 0px 1px #dddddd;
  cursor: pointer;
  background-color: #fff;
  box-sizing: border-box;
}
.plan-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
}
.plan-item-head-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #ffffff;
  padding: 15px;
  height: 130px;
}
.plan-item-head-right-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
}
.plan-item-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px 15px 15px;
}
.plan-item-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}
.plan-item-items-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.plan-item-footer-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 15px;
}
.plan-item-selected-border {
  border: 3px solid #f8cf7c;
}
</style>