<template>
  <Modal
    v-model="isShowModal"
    footer-hide
    :closable="false"
    :mask-closable="false"
  >
    <div class="plan-modal-root-container">
      <img :src="icons.passSolid" />
      <span style="font-size: 23px; font-weight: bold; margin-top: 20px"
        >付款计划</span
      >
      <span style="font-size: 23px; font-weight: bold; margin-top: 5px">{{
        id
      }}</span>
      <span style="font-size: 12px; margin-top: 20px">已生成</span>
      <Button
        shape="circle"
        type="default"
        style="width: 100px; margin-top: 30px"
        @click="$emit('complete')"
        >关闭</Button
      >
    </div>
  </Modal>
</template>

<script>
import * as icon from "../../../common/icon";
export default {
  components: {},
  data() {
    return {
      icons: icon,
      isShowModal: false,
      id: "",
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped>
.plan-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
</style>