<template>
  <div class="plan-step-root-container">
    <span style="font-size:32px;font-weight:bold;margin-bottom:20px">为事件#{{dataSource.milestone_id}}#创建付款计划</span>

    <milestone-item :dataSource="dataSource" style="width:550px;margin-top:100px;" />

    <Button
      style="margin:50px 10px;width:150px;"
      size="large"
      type="primary"
      @click="$emit('next')"
    >生成付款列表</Button>
  </div>
</template>

<script>
import MilestoneItem from "../Item/MilestoneItem";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
    };
  },
  components: {
    "milestone-item": MilestoneItem,
  },
  methods: {
  },
};
</script>

<style scoped>
.plan-step-root-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>