<template>
  <div class="plan-step-page-container">
    <Button type="primary" icon="ios-arrow-back" class="back-button" @click="$emit('back')"></Button>

    <span style="font-size: 32px; font-weight: bold">已保存，最后命名，添加附件及备注</span>

    <my-input style="margin-top: 50px; width: 500px" ref="formName" title="付款计划名称" size="large"
      v-model="dataSource.name" :validate="nameValidate" />

    <div class="plan-step-header-container">
      <span style="font-size: 23px; font-weight: bold">全部附件{{ dataSource.accessories.length }}个</span>
      <Icon @click="upload" type="ios-cloud-upload-outline" style="cursor: pointer" :size="30" />
    </div>

    <accessories-list :dataSource="dataSource.accessories" />

    <my-textarea style="margin-top: 50px; width: 500px; margin-bottom: 20px" ref="formMore" title="添加备注" size="large"
      :rows="4" v-model="dataSource.more" />

    <span v-if="dataSource.zip_url != ''">已上传</span>
    <Upload :show-upload-list="false" :action="uploadPath" :before-upload="handleBeforeUpload"
      :on-success="uploadSuccess" :data="uploadParam" :format="['zip']" :max-size="30000"
      :on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize">
      <Button :loading="uploadLoading" type="primary" style="width: 130px">提交佐证材料</Button>
    </Upload>

    <div class="tools-container">
      <Button @click="submit" type="primary" size="large">创建付款计划</Button>
    </div>

    <accessories-drawer ref="accessoriesDrawer" @add="add" />
  </div>
</template>

<script>
import AccessoriesList from "../../../components/List/AccessoryList";
import AccessoriesDrawer from "../../../components/Drawer/AccessoriesDrawer";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyInput from "../../../components/Form/MyInput";
import { apiHost } from "../../../common/env";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    "my-input": MyInput,
    "my-textarea": MyTextArea,
    "accessories-list": AccessoriesList,
    "accessories-drawer": AccessoriesDrawer,
  },
  data() {
    return {
      uploadPath: apiHost + "pay_plan/uploadZip",
      uploadParam: {},
      uploadLoading: false,
    };
  },
  mounted() {
    let token = localStorage.getItem("token");
    this.uploadParam = { token: token };
  },
  methods: {
    add(acc) {
      acc.type = 1;
      this.dataSource.accessories.push(acc);
    },
    submit() {
      // if (this.dataSource.zip_url == "") {
      //   this.$Message.warning("清提交佐证材料");
      //   return;
      // }
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          this.$parent.$parent.showConfirm();
        })
        .catch((error) => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.dataSource.name == "") {
          reject("请填写付款计划名称");
        } else {
          resolve();
        }
      });
    },
    upload() {
      this.$refs.accessoriesDrawer.open();
    },
    handleBeforeUpload() {
      this.uploadLoading = true;
      return true;
    },
    handleFormatError(file) {
      this.uploadLoading = false;
      this.$Notice.warning({
        title: "文件类型不正确",
        desc: "只能上传Zip",
      });
    },
    handleMaxSize(file) {
      this.uploadLoading = false;
      this.$Notice.warning({
        title: "文件过大",
        desc: "不得大于30M",
      });
    },
    uploadSuccess(res, file, fileList) {
      this.uploadLoading = false;
      this.dataSource.zip_url = res.msg.url;
    },
  },
};
</script>

<style scoped>
.plan-step-page-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.plan-step-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.plan-step-main-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 10px;
}
.plan-step-main-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  min-height: 50px;
}
.plan-step-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
.accessories-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>