<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div class="plan-queue-confirm-modal-root-container">
      <span
        style="
          font-size: 23px;
          font-weight: 500;
          margin-top: 50px;
          margin-bottom: 20px;
        "
        >确定发送付款计划</span
      >
      <div class="plan-queue-confirm-modal-guid-list">
        <span
          v-for="(item, index) in dataSource.guid_list"
          :key="index"
          style="margin-right: 15px"
          >#{{ item }}#</span
        >
      </div>

      <fee
        :amount="dataSource.total_amount"
        :primarySize="32"
        :secondarySize="29"
      />
      <span style="font-size: 12px; font-weight: 500; margin-top: 15px"
        >共计{{ dataSource.total_count }}笔交易</span
      >

      <Button
        type="primary"
        shape="circle"
        size="large"
        style="margin-top: 100px; width: 100px"
        @click="send"
        >确定</Button
      >
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import Fee from "../../../components/Fee";
export default {
  components: {
    fee: Fee,
  },
  data() {
    return {
      isShowModal: false,
      dataSource: {
        total_count: 0,
        guid_list: [],
        total_amount: 0,
      },
    };
  },
  methods: {
    ...mapActions({
      planSendQueueAction: "planSendQueue",
    }),
    open(dataSource) {
      this.dataSource = dataSource;
      this.isShowModal = true;
    },
    send() {
      this.planSendQueueAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id_arr: this.dataSource.guid_list,
      })
        .then((res) => {
          this.$Message.success("发送到处理队列成功");
          this.isShowModal = false;
          this.$emit("close");
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.plan-queue-confirm-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}
.plan-queue-confirm-modal-guid-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 70%;
  margin-bottom: 50px;
}
</style>