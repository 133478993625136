<template>
  <div class="plan-step-root-container">
    <Button
      type="primary"
      icon="ios-arrow-back"
      class="back-button"
      @click="$emit('back')"
    ></Button>
    <span style="font-size: 32px; font-weight: bold; margin-bottom: 20px"
      >添加需要引用的里程碑事件</span
    >
    <div class="plan-step-row">
      <span>没有或不想关联里程碑事件🤔️？您还可以</span>
      <div
        class="plan-step-btn"
        @click="
          () => {
            $emit('next', { page: 4 });
          }
        "
      >
        <img :src="icons.xls" style="width: 25px; height: 25px" />
        <span style="margin-left: 10px">通过Excel上传付款列表</span>
      </div>
      <a :href="ossHost+'upload_template_pay.xlsx'" style="font-size:12px;color:#3483BF;">下载模板</a>
    </div>

    <div class="plan-table-container">
      <div class="plan-table-header-container">
        <span style="font-weight: bold">项目下的全部里程碑事件</span>
      </div>
      <div class="plan-item-container">
        <milestone-item
          v-for="item in milestoneList"
          :key="item.milestone_id"
          :dataSource="item"
          @click.native="select(item)"
        />
      </div>
    </div>

    <Button
      style="margin: 50px 0; width: 150px"
      size="large"
      type="primary"
      @click="next"
      >生成付款列表</Button
    >
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../../common/icon";
import { ossHost } from "../../../common/env";
import MilestoneItem from "../Item/MilestoneItem";
export default {
  props: {
    dataSource: Array,
    projectId: Number,
  },
  components: {
    "milestone-item": MilestoneItem,
  },
  data() {
    return {
      icons: icon,
      ossHost: ossHost,
      milestoneList: [],
    };
  },
  mounted() {
    this.milestoneGetListAction({
      employer_id: localStorage.getItem("employer_id"),
      project_id: this.projectId,
    })
      .then((res) => {
        for (let item of res) {
          item.isSelected = false;
          item.create_time = moment(item.create_time).format(
            "YYYY-MM-DD HH:mm"
          );
          for (let id of this.dataSource) {
            if (item.milestone_id == id) {
              item.isSelected = true;
              break;
            }
          }
        }
        this.milestoneList = res;
      })
      .catch((error) => {
        console.error(error);
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      milestoneGetListAction: "milestoneGetList",
    }),
    select(item) {
      item.isSelected = !item.isSelected;
    },
    next() {
      let milestone_id_arr = [];
      for (let item of this.milestoneList) {
        if (item.isSelected) {
          milestone_id_arr.push(item.milestone_id);
        }
      }
      if (milestone_id_arr.length == 0) {
        this.$Message.error("请选择里程碑");
        return;
      }
      this.$emit("next", { page: 5, milestone_id_arr: milestone_id_arr });
    },
  },
};
</script>

<style scoped>
.plan-step-root-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.plan-step-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.plan-step-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  background-color: aliceblue;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
}
.plan-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  width: 700px;
}
.plan-table-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  min-height: 50px;
  padding-left: 20px;
}
.plan-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>