<template>
  <div class="plan-root-container">
    <div class="plan-header-container">
      <span class="page-title">付款计划</span>
      <div class="plan-tools-container">
        <Icon
          type="ios-trash"
          size="20"
          color="#3385A6"
          style="margin-right: 5px"
        />
        <span
          style="
            color: #3385a6;
            font-size: 15px;
            margin-right: 20px;
            cursor: pointer;
          "
          @click="showHistoryModal"
          >作废的计划</span
        >
        <Icon
          type="ios-paper-plane"
          size="20"
          color="#3385A6"
          style="margin-right: 5px"
        />
        <span
          style="color: #3385a6; font-size: 15px; cursor: pointer"
          @click="showRecordModal"
          >发送记录</span
        >
      </div>
    </div>

    <div class="plan-fast-tools-container">
      <div class="plan-btn" @click="createPlan(1)">
        <Icon
          type="ios-paper-outline"
          size="18"
          color="#3385A6"
          style="margin-right: 5px"
        />
        <span>创建付款计划</span>
      </div>
      <div class="plan-btn" @click="showQueueModal">
        <Icon
          type="ios-paper-plane"
          size="18"
          color="#3385A6"
          style="margin-right: 5px"
        />
        <span>发送付款计划到处理队列</span>
      </div>
    </div>

    <span style="font-size: 23px; font-weight: bold; margin-top: 50px"
      >接收到的里程碑事件</span
    >

    <div class="plan-notification-container">
      <span v-if="notification_list.length == 0" style="font-size: 12px"
        >🎉 恭喜您，没有任何未完成的任务</span
      >
      <event-item
        v-else
        v-for="item in notification_list"
        :key="item.id"
        :data-source="item"
        @click.native="createPlan(2, item)"
      ></event-item>
      <div style="min-width: 1px; height: 1px"></div>
    </div>

    <div
      class="plan-header-container"
      style="justify-content: flex-start; margin-top: 50px"
    >
      <span style="font-size: 23px; font-weight: bold">创建好的计划👍</span>
      <slider-tabbar
        style="margin-left: 30px; flex: 1"
        :tabs="tabs"
        @on-change="onBarChange"
      ></slider-tabbar>
    </div>

    <div class="plan-main-container">
      <plan-item
        v-for="item in plan_list"
        :key="item.pay_plan_id"
        :data-source="item"
        :can-show-modal="true"
        :can-show-button="true"
      ></plan-item>
    </div>

    <create-plan-modal ref="createPlanModal" @close="refresh" />
    <plan-queue-modal ref="planQueueModal" />
    <plan-record-modal ref="planRecordModal" />
    <plan-history-modal ref="planHistoryModal" />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import * as icon from "../../common/icon";
import EventItem from "./Item/EventItem";
import PlanItem from "./Item/PlanItem";
import SliderTabbar from "../../components/SlideTabbar";
import CreatePlanModal from "./Modal/CreatePlanModal";
import PlanQueueModal from "./Modal/PlanQueueModal";
import PlanRecordModal from "./Modal/PlanRecordModal";
import PlanHistoryModal from "./Modal/PlanHistoryModal";
export default {
  components: {
    "event-item": EventItem,
    "plan-item": PlanItem,
    "slider-tabbar": SliderTabbar,
    "plan-queue-modal": PlanQueueModal,
    "create-plan-modal": CreatePlanModal,
    "plan-record-modal": PlanRecordModal,
    "plan-history-modal": PlanHistoryModal,
  },
  data() {
    return {
      icons: icon,
      currentIndex: 0,
      tabs: [
        {
          name: "按创建时间排序",
        },
        {
          name: "按最后更新时间排序",
        },
      ],
      notification_list: [],
      plan_list: [],
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions({
      planGetListAction: "planGetList",
      planGetReceiveMilestoneListAction: "planGetReceiveMilestoneList",
    }),
    createPlan(type, item) {
      this.$refs.createPlanModal.open(type, item);
    },
    showQueueModal() {
      this.$refs.planQueueModal.open();
    },
    showRecordModal() {
      this.$refs.planRecordModal.open();
    },
    showHistoryModal() {
      this.$refs.planHistoryModal.open();
    },
    refresh() {
      this.planGetReceiveMilestoneListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          for (let item of res) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          this.notification_list = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
      this.getPlanList();
    },
    getPlanList() {
      this.planGetListAction({
        employer_id: localStorage.getItem("employer_id"),
        state: 1,
        orderby_type: this.currentIndex + 1,
      })
        .then((res) => {
          res.map((item) => {
            item.isValid = true;
            item.update_time = moment(item.update_time).format(
              "YYYY-MM-DD HH:mm"
            );
          });
          this.plan_list = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    onBarChange(index) {
      this.currentIndex = index;
      this.getPlanList();
    },
  },
};
</script>

<style scoped>
.plan-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 150px;
  background-color: #f9f9f9;
}
.plan-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.plan-tools-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.plan-notification-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: #f3f3f3;
  margin-top: 10px;
  border-radius: 10px;
  overflow-x: scroll;
}
.plan-fast-tools-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.plan-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #e3eafa;
  font-size: 12px;
  font-weight: bold;
  margin-right: 20px;
  cursor: pointer;
}
.plan-main-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  overflow-x: scroll;
}
.plan-wrapper-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
</style>