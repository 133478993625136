<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div class="plan-queue-modal-root-container" v-if="isShowModal">
      <div class="page-title">发送付款计划到处理队列</div>

      <div class="plan-queue-modal-tip-container">
        <span>🎉温馨提示</span>
        <span
          >1-一旦您将付款计划发送到处理队列，除了添加附件和修改备注外，您将无法再对计划中包含和引用的信息做任何调整</span
        >
        <span
          >2-游用工会对您提交的付款计划中包含的交易进行合规校验，可能有的交易无法满足支付条件</span
        >
        <span
          >3-暂时未满足支付条件的交易会在「处理队列」中以任务形式挂起，您可以通过其它方式（如线下，或后续创建新的付款计划）处理</span
        >
      </div>

      <div class="plan-queue-modal-list-container">
        <plan-item
          v-for="item in plan_list"
          :key="item.pay_plan_id"
          :data-source="item"
          @click.native="selItem(item)"
        ></plan-item>
      </div>

      <div class="tools-container">
        <Button type="primary" size="large" @click="sendPlan"
          >发送付款计划</Button
        >
      </div>

      <plan-queue-confirm-modal
        ref="planQueueConfirmModal"
        @close="close"
      ></plan-queue-confirm-modal>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import PlanItem from "../Item/PlanItem";
import PlanQueueConfirmModal from "./PlanQueueConfirmModal";
export default {
  components: {
    "plan-item": PlanItem,
    "plan-queue-confirm-modal": PlanQueueConfirmModal,
  },
  data() {
    return {
      isShowModal: false,

      plan_list: [],
    };
  },
  methods: {
    ...mapActions({
      planGetListAction: "planGetList",
    }),
    open() {
      this.getList();
      this.isShowModal = true;
    },
    close() {
      this.$parent.refresh();
      this.isShowModal = false;
    },
    sendPlan() {
      let dataSource = {
        guid_list: [],
        total_amount: 0,
        total_count: 0,
      };
      for (let item of this.plan_list) {
        if (item.isSelected) {
          dataSource.total_count += 1;
          dataSource.guid_list.push(item.pay_plan_id);
          dataSource.total_amount += item.total_amount;
        }
      }
      this.$refs.planQueueConfirmModal.open(dataSource);
    },
    selItem(item) {
      item.isSelected = !item.isSelected;
    },
    getList() {
      this.planGetListAction({
        employer_id: localStorage.getItem("employer_id"),
        state: 1,
        orderby_type: 1,
      })
        .then((res) => {
          res.map((item) => {
            item.isValid = true;
            item.isSelected = false;
            item.update_time = moment(item.update_time).format(
              "YYYY-MM-DD HH:mm"
            );
            return item;
          });
          this.plan_list = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.plan-queue-modal-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 285px;
  padding-right: 150px;
}
.plan-queue-modal-tip-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 15px 0;
  border-radius: 8px;
  background-color: #fffdf5;
}
.plan-queue-modal-list-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
</style>