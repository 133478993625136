<template>
  <Modal
    v-model="isShowModal"
    footer-hide
    :closable="false"
    :mask-closable="false"
  >
    <div class="plan-modal-root-container">
      <span style="font-size: 23px; font-weight: bold; margin-top: 20px"
        >创建失败</span
      >
      <div style="margin-top: 50px">
        <div>原因：{{ data.message }}</div>
        <div style="margin-top: 15px">
          下载：<a :href="data.file_url">{{ data.file_url }}</a>
        </div>
      </div>
      <Button
        shape="circle"
        type="default"
        style="width: 100px; margin-top: 30px"
        @click="$emit('complete')"
        >关闭</Button
      >
    </div>
  </Modal>
</template>

<script>
import * as icon from "../../../common/icon";
export default {
  components: {},
  data() {
    return {
      icons: icon,
      isShowModal: false,

      data: {},
    };
  },
  methods: {
    open(data) {
      this.data = data;
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped>
.plan-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
</style>