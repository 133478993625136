<template>
  <div class="file-item-root-container">
    <img
      :src="icons.xls"
      style="width: 30px; height: 30px; margin-right: 15px"
    />
    <div class="file-item-title-container">
      <span style="font-size: 15px; font-weight: bold; color: #1f5789">{{
        dataSource.file_name
      }}</span>
      <span
        style="
          font-size: 12px;
          font-weight: 500;
          color: #5e5e5e;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        "
        >备注：{{ dataSource.remark }}</span
      >
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
};
</script>

<style scoped>
.file-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #f6f7f8;
}
.file-item-title-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>