<template>
  <div class="plan-step-root-container">
    <span style="font-size:32px;font-weight:bold;margin-bottom:20px">选择需要关联的项目</span>
    <span style="font-size:13px;font-weight:bold;">当前有{{projectList.length}}个项目正在运行</span>

    <div class="plan-step-main-container">
      <project-item
        v-for="item in projectList"
        :key="item.id"
        :dataSource="item"
        @click.native="next(item)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ProjectItem from "../Item/ProjectItem";
export default {
  components: {
    "project-item": ProjectItem,
  },
  data() {
    return {
      projectList: [],
    };
  },
  mounted() {
    this.projectGetListAction({
      employer_id: localStorage.getItem("employer_id"),
    })
      .then((res) => {
        this.projectList = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      projectGetListAction: "projectGetList",
    }),
    next(item) {
      this.$emit("next", item);
    },
  },
};
</script>

<style scoped>
.plan-step-root-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.plan-step-main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-top: 50px;
}
</style>