<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div class="plan-history-modal-root-container" v-if="isShowModal">
      <div class="page-title">作废的付款计划</div>

      <Button
        type="primary"
        size="large"
        style="width: 150px; margin-top: 30px; margin-bottom: 30px"
        @click="deletePlans"
        >永久删除</Button
      >

      <div class="plan-history-modal-list-container">
        <plan-item
          v-for="item in plan_list"
          :key="item.pay_plan_id"
          :data-source="item"
          @click.native="selItem(item)"
        ></plan-item>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import PlanItem from "../Item/PlanItem";
export default {
  components: {
    "plan-item": PlanItem,
  },
  data() {
    return {
      isShowModal: false,

      plan_list: [],
    };
  },
  methods: {
    ...mapActions({
      planGetListAction: "planGetList",
      planSetStateAction: "planSetState",
    }),
    open() {
      this.isShowModal = true;

      this.getList();
    },
    selItem(item) {
      item.isSelected = !item.isSelected;
    },
    getList() {
      this.planGetListAction({
        employer_id: localStorage.getItem("employer_id"),
        state: -1,
      })
        .then((res) => {
          for (let item of res) {
            item.isSelected = false;
          }

          this.plan_list = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    deletePlans() {
      let ids = [];
      for (let item of this.plan_list) {
        if (item.isSelected) {
          ids.push(item.pay_plan_id);
        }
      }

      this.planSetStateAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id_arr: ids,
        state: -2,
      })
        .then((res) => {
          this.$Message.success("删除成功");
          this.getList();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.plan-history-modal-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 285px;
  padding-right: 150px;
}
.plan-history-modal-list-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
</style>