<template>
  <Modal
    v-model="isShowModal"
    :closable="false"
    :mask-closable="false"
    @on-cancel="onCancel"
    @on-ok="onOk"
  >
    <div class="plan-repeat-warning-modal-root-container">
      <div style="font-weight: bold; font-size: 18px">
        以下用户三天内有过相同金额的发放记录，请确认是否继续？
      </div>
      <div
        v-for="(key, value) in repeat_data"
        :key="key"
        style="margin-top: 20px; font-weight: bold"
      >
        {{ value }}：{{ key }}元
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      isShowModal: false,

      repeat_data: [],
    };
  },
  methods: {
    show(data) {
      let map = {};
      for (let item of data.repeat_arr) {
        if (!map[item.client_name]) {
          map[item.client_name] = item.amount;
        }
      }
      this.repeat_data = map;

      this.isShowModal = true;
    },
    onCancel() {
      this.isShowModal = false;
      this.$emit("close");
    },
    onOk() {
      this.$emit("plan-create");
    },
  },
};
</script>

<style scoped>
.plan-repeat-warning-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 50px;
}
</style>