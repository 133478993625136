<template>
  <div class="plan-step-page-container">
    <Button type="primary" icon="ios-arrow-back" class="back-button" @click="$emit('back')"></Button>
    <div class="plan-step-header-container">
      <div class="plan-step-title-container" style="flex: 1">
        <span style="font-size: 32px; font-weight: bold">请查看生成的付款列表</span>
        <span style="font-size: 14px; color: #4f4e4e; margin-top: 10px">系统识别合格上传{{ payList.length }}笔交易</span>
        <span style="font-size: 14px; color: #4f4e4e; margin-top: 10px" v-if="errorList.length > 0">未通过{{ errorList.length }}笔，总金额{{
            errorTotalAmount
          }}。可能的原因包括身份信息、银行卡信息不足，或者Ta不在您的联系人里</span>
        <a :href="errorUrl" style="font-size: 12px; color: #3483bf; margin-top: 10px">导出未通过的excel</a>
        <!-- <div class="plan-step-error-container">
          <Poptip
            v-for="(item, index) in errorList"
            :key="index"
            trigger="hover"
            title="错误详情"
            :content="item.msg"
          >
            <span
              style="
                display: inline-block;
                margin: 10px;
                border-radius: 4px;
                background-color: #debfbf;
                padding: 5px 10px;
              "
              >{{ item.client_name }}</span
            >
          </Poptip>
        </div> -->
      </div>
      <div class="plan-step-title-container" style="align-items: flex-end">
        <fee :amount="totalAmount" :primarySize="32" :secondarySize="28" />
        <span style="font-size: 13px; font-weight: bold; margin-top: 10px">合计金额</span>
      </div>
    </div>

    <pay-list :dataSource="payList" />

    <div style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 50px 0px;
      ">
      <Button size="large" type="primary" style="width: 200px" @click="submit">保存通过的付款列表</Button>
      <Button size="large" type="success" style="margin-left: 30px; width: 200px" @click="syncData">同步</Button>

      <a :href="payUrl" style="font-size: 12px; color: #3483bf; margin-left: 30px">导出通过的excel</a>

      <a :href="commentUrl" style="font-size: 12px; color: #3483bf; margin-left: 30px">导出业务摘要</a>

      <a :href="checkUrl" style="font-size: 12px; color: #3483bf; margin-left: 30px">导出结算清单</a>

      <a @click="downloadTempZip" style="font-size: 12px; color: #3483bf; margin-left: 30px">下载临时的佐证材料</a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PayList from "../../../components/List/PayList";
import { numberFormat } from "../../../utils/numberHelper";
import Fee from "../../../components/Fee";
export default {
  props: {
    milestoneIds: Array,
    projectId: Number,
    excel: Array,
    isByExcel: Boolean,
  },
  components: {
    fee: Fee,
    "pay-list": PayList,
  },
  data() {
    return {
      payList: [],
      errorList: [],
      errorUrl: "",
      payUrl: "",
      commentUrl: "",
      checkUrl: "",
    };
  },
  computed: {
    totalAmount() {
      let result = 0;
      for (let item of this.payList) {
        result += item.amount;
      }
      return result;
    },
    errorTotalAmount() {
      let result = 0;
      for (let item of this.errorList) {
        result += item.amount;
      }
      let format = numberFormat(result);
      return format.integer + "." + format.decimal;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      addMembersAction: "planAddMembers",
      milestoneGetPayListAction: "milestoneGetPayList",
      planCreateTempSBYZipAction: "planCreateTempSBYZip",
      milestoneGetExcelDataAction: "milestoneGetExcelData",
    }),
    init() {
      return new Promise((resolve, reject) => {
        if (this.isByExcel == true) {
          this.milestoneGetExcelDataAction({
            employer_id: localStorage.getItem("employer_id"),
            project_id: this.projectId,
            excel: this.excel,
          })
            .then((res) => {
              this.payList = res.pay_list;
              this.errorList = res.error_list;
              this.errorUrl = res.error_url;
              this.payUrl = res.pay_url;
              this.commentUrl = res.comment_url;
              this.checkUrl = res.check_url;
              resolve();
            })
            .catch((error) => {
              this.$Message.error(error.msg);
              reject(error);
            });
        } else {
          this.milestoneGetPayListAction({
            employer_id: localStorage.getItem("employer_id"),
            milestone_id_arr: this.milestoneIds,
          })
            .then((res) => {
              this.payList = res;
              resolve();
            })
            .catch((error) => {
              this.$Message.error(error);
              reject(error);
            });
        }
      });
    },
    submit() {
      if (this.payList.length == 0) {
        this.$Message.error("至少包含一条付款");
        return;
      }
      if (this.payList.length >= 500) {
        this.$Message.error("不能大于500笔交易");
        return;
      }
      this.$emit("next");
    },
    syncData() {
      this.$Spin.show();
      // 所有 state 不是 1 的 client_user_id
      let client_user_id_arr = [];
      for (let item of this.payList) {
        if (
          item.uc_state != 1 &&
          client_user_id_arr.indexOf(item.client_user_id) == -1
        ) {
          client_user_id_arr.push(item.client_user_id);
        }
      }
      this.addMembersAction({
        client_user_id_arr: client_user_id_arr,
        project_id: this.projectId,
      })
        .then((res) => {
          this.init().finally(() => {
            this.$Spin.hide();
          });
        })
        .catch((error) => {
          this.$Message.error(error);
        })
        .finally(() => {
          this.$Spin.hide();
        });
    },
    downloadTempZip() {
      this.planCreateTempSBYZipAction({
        project_id: this.projectId,
        pay_list: this.payList,
      })
        .then((res) => {
          // window.open(res.url);
          let link = document.createElement("a");
          link.href = res.url;
          link.click();
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.plan-step-page-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.plan-step-header-container {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.plan-step-title-container {
  display: flex;
  flex-direction: column;
}
.plan-step-error-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 150px;
  overflow-y: scroll;
}
</style>