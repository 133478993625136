<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="create-plan-modal-root-container">
      <plan-step1 v-if="currentPage == 1" @next="page1Next" />
      <plan-step2 v-if="currentPage == 2" @next="currentPage = 5" :dataSource="tempMilestoneItem" />
      <plan-step3 v-if="currentPage == 3" @next="page3Next" @back="currentPage = 1"
        :dataSource="planForm.milestone_id_arr" :projectId="planForm.project_id" />
      <plan-step4 v-if="currentPage == 4" @next="currentPage = 5" :projectId="planForm.project_id"
        :excel="planForm.excel" @back="currentPage = 3" @exit="isShowModal = false" />
      <plan-step5 v-if="currentPage == 5" @next="currentPage = 6" :milestoneIds="planForm.milestone_id_arr"
        :projectId="planForm.project_id" :excel="planForm.excel" :isByExcel="planForm.isByExcel" @back="page5Back" />
      <plan-step6 v-if="currentPage == 6" @back="currentPage = 5" @close="close" :dataSource="planForm" />
    </div>

    <plan-create-fail ref="planCreateFail" @complete="failComplete" />
    <plan-create-confirm ref="planCreateConfirm" :dataSource="planForm" @confirm="confirm" />
    <plan-create-complete ref="planCreateComplete" @complete="complete" />
    <plan-repeat-warning ref="planRepeatWarning" @plan-create="planCreate" @close="warningClose" />
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PlanStep1 from "../Page/PlanStep1";
import PlanStep2 from "../Page/PlanStep2";
import PlanStep3 from "../Page/PlanStep3";
import PlanStep4 from "../Page/PlanStep4";
import PlanStep5 from "../Page/PlanStep5";
import PlanStep6 from "../Page/PlanStep6";
import PlanCreateFail from "../Modal/PlanCreateFail";
import PlanRepeatWarning from "./PlanRepeatWarning.vue";
import PlanCreateConfirm from "../Modal/PlanCreateConfirm";
import PlanCreateComplete from "../Modal/PlanCreateComplete";
export default {
  components: {
    "plan-step1": PlanStep1,
    "plan-step2": PlanStep2,
    "plan-step3": PlanStep3,
    "plan-step4": PlanStep4,
    "plan-step5": PlanStep5,
    "plan-step6": PlanStep6,
    "plan-create-fail": PlanCreateFail,
    "plan-repeat-warning": PlanRepeatWarning,
    "plan-create-confirm": PlanCreateConfirm,
    "plan-create-complete": PlanCreateComplete,
  },
  data() {
    return {
      isShowModal: false,
      currentPage: 1,

      type: 1,
      tempMilestoneItem: {},
      planForm: {
        employer_id: localStorage.getItem("employer_id"),
        project_id: 0,
        zip_url: 0,
        name: "",
        more: "",
        excel: [],
        accessories: [],
        milestone_id_arr: [],
        isByExcel: false,
      },
    };
  },
  methods: {
    ...mapActions({
      planGetCreateAction: "planGetCreate",
      planVerifyExcelRepeatAction: "planVerifyExcelRepeat",
    }),
    open(type, item) {
      this.planForm = {
        employer_id: localStorage.getItem("employer_id"),
        project_id: 0,
        zip_url: "",
        name: "",
        more: "",
        excel: [],
        accessories: [],
        milestone_id_arr: [],
        isByExcel: false,
      };
      this.type = type;
      this.currentPage = type;
      if (this.type == 2) {
        this.tempMilestoneItem = item;
        this.planForm.project_id = item.project_id;
        this.planForm.milestone_id_arr.push(item.milestone_id);
      }
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
    },
    page1Next(item) {
      this.planForm.project_id = item.project_id;
      this.currentPage = 3;
    },
    page3Next(obj) {
      if (obj.page == 4) {
        this.planForm.isByExcel = true;
      } else {
        this.planForm.milestone_id_arr = obj.milestone_id_arr;
        this.planForm.isByExcel = false;
      }
      this.currentPage = obj.page;
    },
    page5Back() {
      if (this.type == 1) {
        if (this.planForm.isByExcel == true) {
          this.currentPage = 4;
        } else {
          this.currentPage = 3;
        }
      } else {
        this.currentPage = 2;
      }
    },
    showConfirm() {
      this.$refs.planCreateConfirm.open();
    },
    confirm() {
      this.$refs.planCreateConfirm.close();

      this.planVerifyExcelRepeatAction({
        employer_id: localStorage.getItem("employer_id"),
        excel: this.planForm.excel,
      })
        .then((res) => {
          if (res.repeat_arr.length == 0) {
            this.planCreate();
          } else {
            this.$refs.planRepeatWarning.show(res);
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    complete() {
      this.$refs.planCreateComplete.close();
      this.close();
    },
    failComplete() {
      this.$refs.planCreateFail.close();
      this.close();
    },
    warningClose() {
      this.close();
    },
    planCreate() {
      this.$Spin.show();
      this.planGetCreateAction(this.planForm)
        .then((res) => {
          if (!res.pay_plan_id) {
            this.$refs.planCreateFail.open(res);
          } else {
            this.$refs.planCreateComplete.open(res.pay_plan_id);
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        })
        .finally(() => {
          this.$Spin.hide();
        });
    },
  },
};
</script>

<style scoped>
.create-plan-modal-root-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 50px;
  padding-left: 285px;
  padding-right: 150px;
  background-color: #f9f9f9;
  overflow: hidden;
}
</style>